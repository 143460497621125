.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mainContent {
  flex: 1;
  padding: 20px;
  background-color: #f8f9fa;
}

.infoHeader {
  display: flex; 
  justify-content: center; /* 가로 정렬 */
  align-items: center; /* 세로 정렬 */
  height: 120px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom:-50px;
}

.contentWrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sliderContainer {
  width: 60%;
  margin-bottom: 40px;
}

.searchContainer {
  width: 100%;
}