.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9; /* 배경색 */
}

.form {
    background-color: #fff; /* 폼 배경색 */
    border: 1px solid #ddd; /* 테두리 */
    border-radius: 8px; /* 모서리 둥글게 */
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* 그림자 */
    width: 100%;
    max-width: 400px; /* 최대 너비 */
}

.label {
    display: block;
    margin-bottom: 8px; /* 레이블과 입력 간격 */
}

.input {
    width: 100%;
    padding: 10px; /* 패딩 */
    margin-bottom: 16px; /* 입력 필드 간격 */
    border: 1px solid #ccc; /* 테두리 */
    border-radius: 4px; /* 모서리 둥글게 */
    box-sizing: border-box; /* 패딩과 테두리를 포함하여 총 너비를 계산 */
}

.error {
    color: red; /* 에러 메시지 색상 */
    margin-bottom: 16px; /* 에러 메시지 간격 */
}

.button {
    background-color: #f7cf63; /* 버튼 배경색 */
    color: white; /* 버튼 글자색 */
    padding: 10px; /* 버튼 패딩 */
    border: none; /* 버튼 테두리 제거 */
    border-radius: 4px; /* 모서리 둥글게 */
    cursor: pointer; /* 포인터 커서 */
    width: 100%; /* 버튼 너비 */
    font-size: 16px; /* 버튼 글자 크기 */
}

.button:hover {
    background-color: #76d5be; /* 버튼 호버 색상 */
}
