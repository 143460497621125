
.sliderContainer {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 180px;
  padding: 25px;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.slide {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.cardImage {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
}

.cardContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  padding-top: 20px;
  box-sizing: border-box;
}

.cardTitle {
  font-size: 28px;
  color: black;
  margin: 15px 0;
  text-align: center;
}

.cardDescription {
  font-size: 18px;
  color: #333;
  white-space: pre-wrap;
}

:global(.slick-dots) {
  bottom: 10px;
}

:global(.slick-dots li button:before) {
  font-size: 12px;
  color: #FFD700;
}

:global(.slick-dots li.slick-active button:before) {
  color: #FFC000;
}

@media (max-width: 768px) {
  .sliderContainer {
    width: 100%;
    padding: 15px;
    margin-top: 60px;
    margin-bottom: 120px;
  }

  .cardImage {
    height: 200px;
  }

  .cardContent {
    padding: 10px 0;
  }

  .cardTitle {
    font-size: 24px;
    margin: 10px 0;
  }

  .cardDescription {
    font-size: 16px;
  }
}