.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* 모달창 맨 앞으로 가져오기 기능 추가 : NamYi */
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%; /* 가로 크기 조정 */
    max-width: 400px; /* 최대 가로 크기 설정 */
    min-height: 200px; /* 최소 세로 크기 설정 */
    max-height: 400px; /* 최소 세로 크기 설정 */
    display: flex;
    flex-direction: column;
    align-items: center; /* 중앙 정렬 */
    position: relative; /* 닫기 버튼 위치 조정을 위해 relative 설정 */
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px; /* X 버튼 크기 조정 */
    cursor: pointer;
}

.loginButtons {
    display: flex; /* Flexbox를 사용하여 정렬 */
    justify-content: center; /* 가로 방향 중앙 정렬 */
    width: 80%; /* 부모 요소의 너비를 기준으로 정렬 */
    margin-top: 10%;
}

.kakaoLoginButton {
    max-width: 100%; /* 카카오 로그인 버튼 크기 조정 */
    justify-content: center; /* 가로 방향 중앙 정렬 */
}