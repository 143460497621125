.editPost {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fffdf0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 100px);
  position: relative;
}

.editPost h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.editPost form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.editPost label {
  font-weight: bold;
  color: #555;
}

.editPost input[type="text"],
.editPost textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ffe135;
  border-radius: 4px;
  background-color: #fff;
}

.editPost textarea {
  min-height: 200px;
  resize: vertical;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.buttons button[type="submit"] {
  background-color: #ffe135;
  color: #333;
}

.buttons button[type="submit"]:hover {
  background-color: #ffd700;
}

.buttons button[type="button"] {
  background-color: #f0f0f0;
  color: #333;
}

.buttons button[type="button"]:hover {
  background-color: #e0e0e0;
}