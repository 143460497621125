.postDetail {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fffdf0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 100px);
  position: relative;
  padding-bottom: 60px;
}

.postDetail h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

.postDetail p {
  color: #666;
  margin-bottom: 5px;
}

.postContent {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin: 20px 0;
  line-height: 1.6;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.buttons button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttons button:first-child {
  background-color: #ffe135;
  color: #333;
}

.buttons button:first-child:hover {
  background-color: #ffd700;
}

.buttons button:last-child {
  background-color: #ff6b6b;
  color: #fff;
}

.buttons button:last-child:hover {
  background-color: #ff4757;
}

.comments {
  margin-top: 30px;
}

.comments h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.comment {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.comment p {
  margin-bottom: 5px;
}

.comment button {
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
}

.comment button:first-child {
  background-color: #ffe135;
  color: #333;
}

.comment button:first-child:hover {
  background-color: #ffd700;
}

.comment button:last-child {
  background-color: #ff6b6b;
  color: #fff;
}

.comment button:last-child:hover {
  background-color: #ff4757;
}

.commentForm {
  margin-top: 20px;
}

.commentForm textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ffe135;
  border-radius: 4px;
  resize: vertical;
  min-height: 100px;
}

.commentForm button {
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #ffe135;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.commentForm button:hover {
  background-color: #ffd700;
}