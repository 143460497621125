/* App.module.css 또는 전역 CSS 파일 */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1 0 auto;
}

/* Footer.module.css */
.footer {
    flex-shrink: 0;
    background-color: #f8f9fa;
    padding: 10px 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copyright {
    font-size: 14px;
    color: #6c757d;
}

.nav {
    display: flex;
    gap: 20px;
}

.link {
    color: #6c757d;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}

.link:hover {
    color: #007bff;
}

@media (max-width: 768px) {
    .content {
        flex-direction: column;
        gap: 15px;
    }

    .nav {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}