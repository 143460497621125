.adminPage {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.nav {
    display: flex;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
}

.navButton {
    padding: 10px 20px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    flex: 1;
    font-size: 16px;
    transition: background-color 0.3s;
}

.navButton.active {
    background-color: #e0e0e0;
    font-weight: bold;
}

.content {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}