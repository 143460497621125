.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    position: relative;
}

.closeButton {
    padding: 8px 12px; 
    background-color: #ccc;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.closeButton:hover {
    background-color: #aaa;
}
