.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    flex: 1;
    margin-left: 20%;
    margin-right: 20%;
}

.title {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

.myPage {
    margin-left: 10%;
    margin-right: 10%;
}

.memberDetails {
    margin-bottom: 10%;
}

.welcome_message {
    margin-bottom: 10px;
}

.welcome_message h2, span {
    display: inline;
}

.email, .phone, .lawyerNumber {
    color: #939393; /* 흐리게 보이도록 회색으로 설정 */
    font-size: 14px; /* 크기 조절 */
    margin-bottom: 3px;
    margin-top: 0;
}

.activities {
    display: flex;
    gap: 5%;
}

.diagnosisResult, .activitySummary {
    flex: 1; /* 동일한 비율로 너비를 나누기 */
    background-color: #f5f6f6; /* 원하는 배경색으로 변경 */
    border-radius: 10px; /* 둥근 모서리 */
    padding: 3% 5% 7% 7%;
}

.activitySummary p {
    margin-top: 0;
    margin-bottom: 3px;
}

.activitySummary p big:hover {
    cursor: pointer;
    color: #ead370;
}

.diagnosisResult small {
    color: #939393;
}

.withdraw {
    text-align: center;
    margin-top:15%;
}

.withdraw button {
    color: #aeaeae;
    font-size: 14px;
    background: none; /* 버튼 형태 없애기 */
    border: none; /* 테두리 없애기 */
    cursor: pointer; /* 커서 모양 변경 */
}

svg {
    margin-right:5px;
}

.withdraw button:hover {
    color: #ead370;
}