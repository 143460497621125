body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
}

.ai-chat-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.ai-chat-icon img {
    width: 80px;
    height: 80px;
    cursor: pointer;
    transition: filter 0.3s ease;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3));
}

.ai-chat-icon:hover {
    transform: scale(1.05);
}

.ai-chat-icon img:hover {
    filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.5));
}

.ai-chat-container {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 100%;
    max-width: 500px;
    height: 80%;
    max-height: 600px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    z-index: 1001;
    display: none; 
    opacity: 0; 
    transition: opacity 0.2s ease, transform 0.2s ease;
    flex-direction: column;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.ai-chat-container.open {
    display: flex;
    height: 80%;
    opacity: 1;
    transform: translateY(0); 
}


.ai-chat-header {
    padding: 10px 10px 5px 10px;
    background: linear-gradient(to right, #FFA648, #FFA648);
    border-bottom: 1px solid #ccc;
    color: white;
    font-size: 20px;
    position: relative;
    height: auto;
    line-height: 1.2;
    padding-left: 20px;
}


.ai-chat-subtext {
    display: block;
    font-size: 14px;
    color: white;
    margin-top: 5px;
    opacity: 0.8;
    color: black; 
}

.ai-chat-close-button {
    position: absolute;
    top: 7px;
    right: 5px;
    cursor: pointer;
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    line-height: 1;
}

.ai-chat-body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    z-index: 1; /* 필요한 경우 낮은 값 설정 */
}

.ai-chat-message {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.ai-chat-message-user {
    align-items: flex-end;
}

.ai-chat-message-assistant {
    align-items: flex-start;
}

.ai-chat-message-content {
    max-width: 60%;
    padding: 0px 10px;
    border-radius: 20px;
    background-color: #F1F0F0;
    text-align: left;
    margin-top: 5px;
}

.ai-chat-message-content.user {
    background-color: #ffe59c;
    text-align: left;
}

.ai-chat-footer {
    padding: 10px;
    border-top: 1px solid #ccc;
    display: flex;
}

.ai-chat-input {
    flex: 1;
    padding: 8px;
    box-sizing: border-box;
}

.ai-chat-send-button {
    padding: 8px 12px;
    margin-left: 8px;
    background-color: #ffc933;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

@keyframes dot-flashing {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

.typing-indicator {
    display: flex;
    align-items: center;
    margin-top: -10px;
    justify-content: flex-start; 
}

.typing-indicator span {
    font-size: 16px;
    line-height: 1;
    margin-right: 2px;
}

.typing-indicator .dot {
    animation: dot-flashing 1s infinite linear;
    margin-left: 0.1px;
    margin-right: 0.1px;
    font-size: 20px;
    color: #4CAF50;
}

.typing-indicator .dot:nth-child(1) {
    animation-delay: 0s;
}

.typing-indicator .dot:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-indicator .dot:nth-child(3) {
    animation-delay: 0.4s;
}
