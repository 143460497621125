/* Header.module.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
}

.logoContainer {
    flex: 1;
}

.logo {
    height: 40px;
    width: auto;
}

.nav {
    flex: 2;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.navLink {
    text-decoration: none;
    color: #333;
}
.navLink:hover {
    color: #aeb727;
}

.authContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.authLink {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.authLink:hover {
    color: #aeb727;
}
.authSeparator {
    margin: 0 20px;
}
