.searchContainer {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 300px;
    padding: 20px;
  }
  
  .searchTitle {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .searchForm {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .input {
    flex: 1;
    padding: 10px;
    border: 2px solid #FFD700;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .button {
    padding: 10px 20px;
    background-color: #FFD700;
    color: #000;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #FFC000;
  }
  
  .message {
    text-align: center;
    color: #666;
    margin-top: 20px;
    font-weight: bold;
  }
  
  .resultTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .resultTable th,
  .resultTable td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .resultTable th {
    background-color: #FFD700;
    color: #000;
  }
  
  .resultTable tr:nth-child(even) {
    background-color: #FFF9E6;
  }
  
  /**/

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #FFD700;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination span {
    margin: 0 10px;
}
