.communityContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  min-height: calc(100vh - 100px);
  position: relative;
  padding-bottom: 60px;
}

.communityTitle {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-decoration: none;
  display: inline-block;
}

.communityTitle:hover {
  color: #ffd700;
}

.searchContainer {
  display: flex;
  margin-bottom: 20px;
}

.searchSelect {
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.searchInput {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.searchButton, .resetButton {
  padding: 8px 15px;
  margin-left: 10px;
  background-color: #ffe135;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.searchButton:hover, .resetButton:hover {
  background-color: #ffd700;
}

.createButton {
  padding: 8px 15px;
  background-color: #ffe135;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
  position: relative;
  z-index: 1001; 
}

.createButton.chat-open {
  z-index: 999;  /* 채팅방 열렸을 때 낮춰짐 */
}

.createButton:hover {
  background-color: #ffd700;
}

.postTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* 고정 너비 레이아웃 */
}

.postTable th, .postTable td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.postTable th {
  background-color: #fff5b1;
  font-weight: bold;
}

.postTable th:nth-child(1), /* 글 번호 */
.postTable td:nth-child(1) {
  width: 8%;
}

.postTable th:nth-child(2), /* 제목 */
.postTable td:nth-child(2) {
  width: 50%;
}

.postTable th:nth-child(3), /* 작성자 */
.postTable td:nth-child(3) {
  width: 15%;
}

.postTable th:nth-child(4), /* 작성일 */
.postTable td:nth-child(4) {
  width: 15%;
}

.postTable th:nth-child(5), /* 조회수 */
.postTable td:nth-child(5) {
  width: 12%;
}

.postTable tr:nth-child(even) {
  background-color: #fffdf0;
}

.postTable tr:hover {
  background-color: #fff9c4;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pageButton {
  padding: 8px 12px;
  margin: 0 5px;
  background-color: #ffe135;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pageButton:hover {
  background-color: #ffd700;
}

.activePage {
  background-color: #ffd700;
}