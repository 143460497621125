.profile {
    height: 32px;
    width: auto;
    cursor: pointer;
}

.dropdown {
    position: absolute; /* 절대 위치 */
    top: 40px; /* 프로필 이미지 아래 위치 */
    right: 0; /* 오른쪽 정렬 */
    background-color: white; /* 배경색 */
    border: 1px solid #ccc; /* 테두리 */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
    padding: 10px; /* 패딩 */
    z-index: 1000; /* 다른 요소 위에 표시 */
    display: flex; /* 플렉스박스 사용 */
    flex-direction: column; /* 세로 방향으로 정렬 */
    align-items: flex-start; /* 왼쪽 정렬 */
    white-space: nowrap; /* 줄 바꿈 방지 */
    border-radius: 10px;
}

.separator {
    width: 100%; /* 전체 너비 */
    height: 1px; /* 선의 두께 */
    background-color: #ccc; /* 선 색상 */
    margin: 10px 0; /* 위아래 여백 */
}

.authLink {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.authLink:hover {
    color: #aeb727;
}